import {
  Button,
  Dropdown,
  DropdownAccordion,
  GeneralDropdown,
  RangeSlider,
  ValueProps
} from '@gruene-brise/common-ui/index'
import { useRouter } from 'next/router'
import CaretDown from '@gruene-brise/common-ui/assets/icons/caret-down.svg'
import CaretUp from '@gruene-brise/common-ui/assets/icons/caret-up-simple.svg'
import Cross from '@gruene-brise/common-ui/assets/icons/cross.svg'
import Times from '@gruene-brise/common-ui/assets/icons/times.svg'
import SortAscending from '@gruene-brise/common-ui/assets/icons/sort-ascending.svg'
import SortDescending from '@gruene-brise/common-ui/assets/icons/sort-descending.svg'
import Sort from '@gruene-brise/common-ui/assets/icons/sort.svg'
import HeaderSearchIcon from '@gruene-brise/common-ui/lib/icon/HeaderSearchIcon'
import { CheckboxGroup } from '@gruene-brise/common-ui/lib/form/Checkbox'
import FormInput from '@gruene-brise/common-ui/lib/form/FormInput'
import useCountry from '@gruene-brise/data-access/lib/hooks/useCountry'
import usePublicPharmacyList from '@gruene-brise/data-access/lib/hooks/usePublicPharmacyList'
import useMobile from '@gruene-brise/data-access/lib/utils/useMobile'
import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { BsCheck, BsFunnelFill } from 'react-icons/bs'
import { FaCheck } from 'react-icons/fa'
import { v4 as uuid } from 'uuid'
import { useProductFilterFormState } from './hooks'
import useProductLabel from '@gruene-brise/data-access/lib/hooks/useProductLabel'
import { useProductFilterOptions } from './hooks/useproductFilter'

const MAX_PRICE = 100
interface ProductSearchAndFilterProps {
  onDropdownActive: (bool: boolean) => void
  setFilters: <T>(val: T) => void
  search?: string
}

const ProductSearchAndFilter: React.FC<ProductSearchAndFilterProps> = ({
  onDropdownActive,
  setFilters,
  search
}) => {
  const { t } = useTranslation()
  const { filterOptions } = useProductFilterOptions()

  const manufacturerLabel = useProductLabel({ type: 'manufacturer' })
  const irradiationLabel = useProductLabel({ type: 'irradiation' })
  const geneticsLabel = useProductLabel({ type: 'genetics' })
  const pharmacyList = usePublicPharmacyList()
  const countryOptions = useCountry()
  const mobileRef = useRef<HTMLDivElement>()
  const router = useRouter()

  const {
    form: { watch, register, reset, getValues, setValue },
    handleFilter,
    handleSort,
    sort
  } = useProductFilterFormState(setFilters)

  const isMobile = useMobile(768)
  const [mobileFilterDrawer, setMobileFilterDrawer] = useState<boolean>()
  const values = getValues()
  const handlePriceRangeSlider = (val: number[]) => {
    setValue('price_from', ((val[0] / 100) * MAX_PRICE).toFixed(0), {
      shouldValidate: true
    })
    setValue('price_to', ((val[1] / 100) * MAX_PRICE).toFixed(0), {
      shouldValidate: true
    })
  }
  const handleTHCRangeSlider = (val: number[]) => {
    setValue('thcQuantityFrom', val[0].toFixed(0), { shouldValidate: true })
    setValue('thcQuantityTo', val[1].toFixed(0), { shouldValidate: true })
  }
  const handleCBDRangeSlider = (val: number[]) => {
    setValue('cbdQuantityFrom', val[0].toFixed(0), { shouldValidate: true })
    setValue('cbdQuantityTo', val[1].toFixed(0), { shouldValidate: true })
  }

  const formIsEmpty = Object.values(values).every((val) => !val)
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    if (search) {
      setValue('search', search)
      handleFilter()
    }
  }, [search, setValue, handleFilter])

  useEffect(() => {
    watch(() => {
      handleFilter()
    })
  }, [watch])

  useEffect(() => {
    if (formIsEmpty) {
      handleFilter()
    }
  }, [formIsEmpty])

  useEffect(() => {
    handleFilter()
  }, [sort])

  useEffect(() => {
    const filtersArray = Array.isArray(router.query.filters)
      ? router.query.filters[0]
      : router.query.filters

    const parsedFilters = router?.query?.filters && JSON.parse(filtersArray)
    if (parsedFilters?.nodes?.length) {
      parsedFilters.nodes.forEach((node) => {
        if (node.field === 'category') {
          setValue('category', node.value)
        }
        if (node.field === 'startingPrice' && node?.comparator === 'gte') {
          setValue('price_from', (node.value / 100).toFixed(0), {
            shouldValidate: true
          })
        }
        if (node.field === 'startingPrice' && node?.comparator === 'lte') {
          setValue('price_to', (node.value / 100).toFixed(0), {
            shouldValidate: true
          })
        }
        if (node.field === 'availability') {
          setValue('availability', node.value)
        }
        if (node.field === 'thcQuantityFrom') {
          setValue('thcQuantityFrom', node.value)
        }
        if (node.field === 'thcQuantityTo') {
          setValue('thcQuantityTo', node.value)
        }
        if (node.field === 'cbdQuantityFrom') {
          setValue('cbdQuantityFrom', node.value)
        }
        if (node.field === 'cbdQuantityTo') {
          setValue('cbdQuantityTo', node.value)
        }
        if (node.field === 'genetics') {
          setValue('genetics', node.value)
        }
        if (node.field === 'manufacturer') {
          setValue('manufacturer', node.value)
        }
        if (node.field === 'irradiation') {
          setValue('irradiation', node.value)
        }
        if (node.field === 'origin') {
          setValue('origin', node.value)
        }
      })
    }
    if (router?.query?.pharmacyId) {
      const pharmacyID = Array.isArray(router.query.pharmacyId)
        ? router.query.pharmacyId[0]
        : router.query.pharmacyId
      setValue('pharmacyId', pharmacyID)
    }
  }, [router?.query?.filters])

  const getWindowHeight = () => {
    if (isMobile) {
      mobileRef.current.style.height = window.innerHeight + 'px'
    } else {
      mobileRef.current.style.height = ''
    }
  }

  const hideHeader = () => {
    const header = document.getElementById('header-hide')
    if (!mobileFilterDrawer) {
      header.style.display = 'none'
    } else {
      header.style.display = 'block'
    }
  }

  useEffect(() => {
    window.addEventListener('resize', getWindowHeight)
    getWindowHeight()
    return () => {
      window.removeEventListener('resize', getWindowHeight)
    }
  }, [isMobile])

  const hasFilter = Object.values(getValues()).some((field) => field)
  return (
    <div className="flex flex-col md:space-y-8 justify-center md:items-center md:translate-y-[-3%] relative z-20">
      <div className="w-full px-[6%] mx-5 ml-10 relative hidden md:block">
        <div className="">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleFilter()
            }}
          >
            <FormInput
              placeholder={
                t(
                  'Search for product name, Genetics, Irradiation and Origin...'
                ) as string
              }
              className="w-full"
              onChange={(e) =>
                setValue('search', e.target.value, { shouldValidate: true })
              }
              register={register('search')}
              autoComplete="off"
              inputClassName={
                'focus:border-tertiary text-grey shadow-none border-0 w-full !h-[60px] !text-sm lg:pr-60 drop-shadow-[0px_5px_15px_rgba(14,29,5,0.05)]'
              }
              value={watch('search')}
              type="text"
              rightAddon={
                <div className="flex items-center space-x-3 px-1">
                  <button
                    type="button"
                    className={`${!watch('search') ? 'hidden' : ''} py-2 px-1`}
                    onClick={() => setValue('search', '')}
                  >
                    <Times className="text-grey w-2 h-2" />
                  </button>
                  <Button
                    onClick={handleFilter}
                    size="sm"
                    brand="primary"
                    className="mr-2 !py-4 px-10 md:px-20 bg-black text-white"
                    variant="contained"
                    text={t('Search')}
                  />
                </div>
              }
            />
          </form>
        </div>
      </div>
      <div className="flex items-center justify-between gap-4 mx-3 mt-4 md:hidden">
        <div className="w-full">
          <FormInput
            placeholder={t('Search product')}
            className="w-full"
            register={register('search')}
            autoComplete="off"
            inputClassName={
              'focus:bg-white focus:border-tertiary text-black shadow-none border-0 w-full !h-[42px] !text-sm lg:pr-60 drop-shadow-[0px_5px_15px_rgba(14,29,5,0.05)]'
            }
            value={watch('search')}
            type="text"
            rightAddon={
              <div className="flex items-center space-x-3 px-1">
                <button
                  type="button"
                  className={`${!watch('search') ? 'hidden' : ''} py-2 px-1`}
                  onClick={() => setValue('search', '')}
                >
                  <Times className="text-grey w-2 h-2" />
                </button>
                <Button
                  onClick={handleFilter}
                  size="sm"
                  brand="secondary"
                  className="mr-2 !py-2 !pl-1.5 pr-2.5 flex justify-center items-center"
                  variant="contained"
                  icon={<HeaderSearchIcon color="#fff" />}
                />
              </div>
            }
          />
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={() => {
              setMobileFilterDrawer(true)
              hideHeader()
            }}
            className="flex items-center justify-center w-[42px] h-[42px] bg-tertiary-10 shadow-button_sm py-4 px-3 rounded-md"
          >
            <BsFunnelFill className="text-primary-25 text-md" />
          </button>
        </div>
      </div>
      <div
        className={`transition-all fixed touch-none top-0 right-0 left-0 z-50 backdrop-blur-sm bg-[rgba(0,0,0,0.6)] w-screen h-screen p-4 md:relative md:top-auto md:right-auto md:z-auto md:bg-transparent md:w-auto md:h-auto ${
          isMobile ? 'drawer' : ''
        }  ${!mobileFilterDrawer ? 'inactive' : ''}`}
        style={{ zIndex: 99999999 }}
      >
        <div
          ref={mobileRef}
          className="transition-all flex flex-col justify-between overflow-y-scroll bg-tertiary-50 w-[80%] fixed top-0 right-0 md:relative md:top-auto md:right-auto md:z-auto md:bg-transparent md:w-auto md:h-auto md:overflow-y-visible"
        >
          <div className="w-full p-4">
            <div className="flex md:hidden items-center justify-between text-primary pb-4">
              <h3 className="text-base font-semibold">{t('Filter')}</h3>
              <button
                onClick={() => {
                  setMobileFilterDrawer(false)
                  hideHeader()
                }}
              >
                <Cross className="text-inherit w-[15px] h-[15px]" />
              </button>
            </div>
            <div className="flex flex-col md:flex-row flex-wrap items-center justify-center relative gap-2 lg:gap-1 md:-translate-y-1/2 md:z-20">
              <Dropdown
                containerClassName="w-full md:!w-auto shadow-button_sm"
                buttonClassName="!text-base gray !py-3 border-none"
                onMenuToggle={onDropdownActive}
                placeholder={t('All Product')}
                size="sm"
                options={filterOptions.category.options}
                isMulti
                value={watch('category')?.split(',')}
                onChange={(values: ValueProps[]) => {
                  setValue(
                    'category',
                    values.map((value) => value.value).join(',')
                  )
                }}
                menuClassName="relative md:absolute sm:w-[249px] !rounded-md !border-primary-25"
                arrowIconDown={<CaretDown />}
                arrowIconUp={<CaretUp />}
              />

              <GeneralDropdown
                menuClassName="relative md:absolute !rounded-md bg-tertiary-10 p-3 !border-primary-25"
                containerClassName="w-full md:w-auto bg-tertiary-10"
                buttonClassName="py-2 border-none h-[40px] lg:min-w-[115px]"
                onMenuToggle={onDropdownActive}
                placeholder={t('Price')}
                menuDirection="bottom-left"
                indicator={
                  watch('price_from') || watch('price_to') ? (
                    <div className="flex items-center justify-center w-5 h-5 rounded-full bg-secondary font-bold text-xl text-white">
                      <BsCheck />
                    </div>
                  ) : null
                }
              >
                <div className="flex flex-col w-full md:w-[270px] rounded-md bg-tertiary-10 p-3">
                  <RangeSlider
                    className="w-full h-5 mb-4"
                    onAfterChange={(val) => handlePriceRangeSlider(val)}
                    value={[
                      +watch('price_from'),
                      watch('price_to') &&
                      +watch('price_to') > +watch('price_from')
                        ? +watch('price_to')
                        : watch('price_to') &&
                          +watch('price_to') <= +watch('price_from')
                        ? +watch('price_from')
                        : 100
                    ]}
                    min={0}
                    max={100}
                  />
                  <div className="relative">
                    <div className="flex justify-between w-full pt-1 pb-1">
                      <span className="text-xs font-semibold text-primary">
                        {t('Min Price')}
                      </span>
                      <span className="text-xs font-semibold text-primary">
                        {t('Max Price')}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center space-x-1 w-full">
                    <FormInput
                      className="!my-0 flex-1"
                      type={'number'}
                      step={1}
                      inputClassName="text-center"
                      register={register('price_from')}
                      placeholder={t('From price')}
                      rightAddon={
                        watch('price_from') && (
                          <div className="pr-6 text-grey">{'€'}</div>
                        )
                      }
                    />
                    <div className="w-[15px] h-[1.5px] bg-primary" />
                    <FormInput
                      type="number"
                      step={1}
                      inputClassName="text-center"
                      className="!my-0 flex-1"
                      register={register('price_to')}
                      placeholder={t('To price')}
                      rightAddon={
                        watch('price_to') && (
                          <div className="pr-6 text-grey">{'€'}</div>
                        )
                      }
                    />
                  </div>
                </div>
              </GeneralDropdown>

              <Dropdown
                removeButtonBorder
                containerClassName="w-full md:!w-[180px] shadow-button_sm"
                buttonClassName="!text-base !py-3"
                onMenuToggle={onDropdownActive}
                placeholder={t('Pharmacy')}
                size="sm"
                options={pharmacyList.options}
                value={watch('pharmacyId')}
                isSearchable
                onChange={(values: ValueProps) => {
                  setValue('pharmacyId', values.id as string, {
                    shouldValidate: true
                  })
                }}
                menuClassName="relative md:absolute"
                arrowIconDown={<CaretDown />}
                arrowIconUp={<CaretUp />}
              />
              <Dropdown
                containerClassName="w-full md:!w-auto shadow-button_sm"
                buttonClassName="!text-base !py-3 border-none"
                onMenuToggle={onDropdownActive}
                placeholder={t('Availability')}
                size="sm"
                options={filterOptions.availability.options}
                isMulti
                value={watch('availability')?.split(',')}
                isSearchable
                onChange={(values: ValueProps[]) =>
                  setValue(
                    'availability',
                    values.map((value) => value.value).join(',')
                  )
                }
                menuClassName="relative md:absolute sm:w-[249px] !rounded-md !border-primary-25"
                arrowIconDown={<CaretDown />}
                arrowIconUp={<CaretUp />}
              />
              <GeneralDropdown
                menuClassName="relative md:absolute !border-primary-25"
                containerClassName="w-full md:w-auto"
                onMenuToggle={onDropdownActive}
                buttonClassName="py-2 border-none h-[40px] lg:min-w-[100px]"
                placeholder={t('THC')}
                indicator={
                  watch('thcQuantityFrom') || watch('thcQuantityTo') ? (
                    <div className="flex items-center justify-center w-5 h-5 rounded-full bg-secondary font-bold text-xl text-white">
                      <BsCheck />
                    </div>
                  ) : null
                }
              >
                <div className="flex flex-col w-full md:w-[320px] rounded-md bg-tertiary-10 p-5">
                  <RangeSlider
                    className="w-full h-5 mb-4"
                    onAfterChange={(val) => handleTHCRangeSlider(val)}
                    value={[
                      +watch('thcQuantityFrom'),
                      watch('thcQuantityTo') &&
                      +watch('thcQuantityTo') > +watch('thcQuantityFrom')
                        ? +watch('thcQuantityTo')
                        : watch('thcQuantityTo') &&
                          +watch('thcQuantityTo') <= +watch('thcQuantityFrom')
                        ? +watch('thcQuantityFrom')
                        : 100
                    ]}
                    min={0}
                    max={100}
                  />
                  <div className="relative">
                    <div className="flex justify-between w-full pt-1 pb-1">
                      <span className="text-xs font-semibold text-primary">
                        {t('Min THC')}
                      </span>
                      <span className="text-xs font-semibold text-primary">
                        {t('Max THC')}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center space-x-1 w-full">
                    <FormInput
                      className="!my-0 flex-1"
                      type={'number'}
                      register={register('thcQuantityFrom')}
                      placeholder={t('From THC')}
                      inputClassName="text-center"
                      rightAddon={<div className="p-2 mr-2">%</div>}
                    />
                    <div className="w-[15px] h-[1.5px] bg-primary" />
                    <FormInput
                      type="number"
                      className="!my-0 flex-1"
                      register={register('thcQuantityTo')}
                      placeholder={t('To THC')}
                      inputClassName="text-center"
                      rightAddon={<div className="p-2 mr-2">%</div>}
                    />
                  </div>
                </div>
              </GeneralDropdown>
              <GeneralDropdown
                menuClassName="relative md:absolute !border-primary-25"
                containerClassName="w-full md:w-auto"
                onMenuToggle={onDropdownActive}
                buttonClassName="py-2 border-none h-[40px] lg:min-w-[100px]"
                placeholder={t('CBD')}
                indicator={
                  watch('cbdQuantityFrom') || watch('cbdQuantityTo') ? (
                    <div className="flex items-center justify-center w-5 h-5 rounded-full bg-secondary font-bold text-xl text-white">
                      <BsCheck />
                    </div>
                  ) : null
                }
              >
                <div className="flex flex-col w-full md:w-[320px] rounded-md bg-tertiary-10 p-5">
                  <RangeSlider
                    className="w-full h-5 mb-4"
                    onAfterChange={(val) => handleCBDRangeSlider(val)}
                    value={[
                      +watch('cbdQuantityFrom'),
                      watch('cbdQuantityTo') &&
                      +watch('cbdQuantityTo') > +watch('cbdQuantityFrom')
                        ? +watch('cbdQuantityTo')
                        : watch('cbdQuantityTo') &&
                          +watch('cbdQuantityTo') <= +watch('cbdQuantityFrom')
                        ? +watch('cbdQuantityFrom')
                        : 100
                    ]}
                    min={0}
                    max={100}
                  />
                  <div className="relative">
                    <div className="flex justify-between w-full pt-1 pb-1">
                      <span className="text-xs font-semibold text-primary">
                        {t('Min CBD')}
                      </span>
                      <span className="text-xs font-semibold text-primary">
                        {t('Max CBD')}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center space-x-1 w-full ">
                    <FormInput
                      className="!my-0 flex-1"
                      type={'number'}
                      register={register('cbdQuantityFrom')}
                      placeholder={t('From CBD')}
                      inputClassName="text-center"
                      rightAddon={<div className="p-2 mr-2">%</div>}
                    />
                    <div className="w-[15px] h-[1.5px] bg-primary" />
                    <FormInput
                      type="number"
                      className="!my-0 flex-1"
                      register={register('cbdQuantityTo')}
                      placeholder={t('To CBD')}
                      inputClassName="text-center"
                      rightAddon={<div className="p-2 mr-2">%</div>}
                    />
                  </div>
                </div>
              </GeneralDropdown>
              <Dropdown
                containerClassName="w-full md:!w-auto shadow-button_sm"
                buttonClassName={`!text-base !py-3 border-none`}
                onMenuToggle={onDropdownActive}
                placeholder={t('Genetics')}
                size="sm"
                options={geneticsLabel.options}
                onSearch={(val) => geneticsLabel.fetchProductLabel(val)}
                isLoading={geneticsLabel.isLoading}
                value={watch('genetics')?.split(',')}
                isMulti
                isSearchable
                onChange={(values: ValueProps[]) =>
                  setValue(
                    'genetics',
                    values.map((value) => value.value).join(',')
                  )
                }
                menuClassName="relative md:absolute sm:w-[249px] !rounded-md !border-primary-25"
                arrowIconDown={<CaretDown />}
                arrowIconUp={<CaretUp />}
              />
              <div className="md:hidden w-full flex flex-col gap-2">
                <Dropdown
                  containerClassName="w-full md:!w-auto shadow-button_sm"
                  buttonClassName={`!text-base !py-3 border-none`}
                  onMenuToggle={onDropdownActive}
                  options={manufacturerLabel.options}
                  placeholder={t('Supplier')}
                  size="sm"
                  value={watch('manufacturer')?.split(',')}
                  isMulti
                  isSearchable
                  onSearch={(val) => manufacturerLabel.fetchProductLabel(val)}
                  isLoading={manufacturerLabel.isLoading}
                  onChange={(values: ValueProps[]) =>
                    setValue(
                      'manufacturer',
                      values.map((value) => value.value).join(',')
                    )
                  }
                  menuClassName="relative md:absolute sm:w-[249px] !rounded-md !border-primary-25"
                  arrowIconDown={<CaretDown />}
                  arrowIconUp={<CaretUp />}
                />
                <Dropdown
                  containerClassName="w-full md:!w-auto shadow-button_sm"
                  buttonClassName={`!text-base !py-3 border-none`}
                  onMenuToggle={onDropdownActive}
                  options={irradiationLabel.options}
                  placeholder={t('Irradiation')}
                  size="sm"
                  value={watch('irradiation')?.split(',')}
                  isMulti
                  onChange={(values: ValueProps[]) =>
                    setValue(
                      'irradiation',
                      values.map((value) => value.value).join(',')
                    )
                  }
                  menuClassName="relative md:absolute sm:w-[249px] !rounded-md !border-primary-25"
                  arrowIconDown={<CaretDown />}
                  arrowIconUp={<CaretUp />}
                />
                <Dropdown
                  containerClassName="w-full md:!w-auto shadow-button_sm"
                  buttonClassName={`!text-base !py-3 border-none`}
                  options={countryOptions}
                  placeholder={t('Origin')}
                  size="sm"
                  value={watch('origin')?.split(',')}
                  isMulti
                  isSearchable
                  onChange={(values: ValueProps[]) =>
                    setValue(
                      'origin',
                      values.map((value) => value.value).join(',')
                    )
                  }
                  menuClassName="relative md:absolute sm:w-[249px] !rounded-md !border-primary-25"
                  arrowIconDown={<CaretDown />}
                  arrowIconUp={<CaretUp />}
                />
              </div>
              <DropdownAccordion
                menuDirection="bottom-right"
                dropdownClassName="sm:w-[249px] !rounded-md !border-primary-25"
                externalControl={{ showMenu, setShowMenu }}
                triggerButton={
                  <div
                    className={`flex ${
                      showMenu
                        ? 'border-[1px] border-primary border-solid'
                        : 'border-none'
                    } relative items-center justify-between flex-1 min-w-full md:min-w-[135px] bg-tertiary-10 shadow-button_sm py-2 px-3 rounded-md`}
                  >
                    <span className="text-base text-grey">
                      {t('Filter by')}
                    </span>
                    <BsFunnelFill className="text-grey text-md" />
                    {hasFilter && (
                      <div className="w-1 h-1 rounded-full bg-secondary absolute top-2 right-4" />
                    )}
                  </div>
                }
                fullClassName="hidden md:block w-full md:w-auto"
                header={
                  <div>
                    <p className="text-xs font-normal text-grey mb-4">
                      {t('Applied filters')}
                    </p>
                    <button
                      onClick={() => {
                        reset()
                        router.push({
                          pathname: '/'
                        })
                      }}
                      className="py-[7px] px-[9px] text-primary bg-primary-alpha rounded-md flex items-center"
                    >
                      <Times
                        width="12px"
                        height="12px"
                        className="text-inherit"
                      />
                      <span className="text-xs font-semibold ml-2">
                        {t('Clear all filters')}
                      </span>
                    </button>
                  </div>
                }
                items={[
                  {
                    title: t('Supplier'),
                    content: (
                      <Dropdown
                        options={manufacturerLabel.options}
                        placeholder={t('Choose Supplier')}
                        size="sm"
                        value={watch('manufacturer')?.split(',')}
                        isMulti
                        isSearchable
                        onSearch={(val) =>
                          manufacturerLabel.fetchProductLabel(val)
                        }
                        isLoading={manufacturerLabel.isLoading}
                        initialMenuOpen
                        ignoreClickOutside
                        onChange={(values: ValueProps[]) =>
                          setValue(
                            'manufacturer',
                            values.map((value) => value.value).join(',')
                          )
                        }
                        menuPosition="relative"
                        buttonClassName="border-[0.5px] border-solid border-primary"
                      />
                    ),
                    rightAddon: watch('manufacturer') && (
                      <div className="w-[22px] h-[22px] rounded-full bg-primary-50 flex flex-col justify-center items-center">
                        <FaCheck size={12} className="text-white" />
                      </div>
                    ),
                    id: '8e5e2737-23bd-4cbb-abeb-7cdccd6f0b04'
                  },
                  {
                    title: t('Irradiation'),
                    content: (
                      <div className="flex flex-col gap-2">
                        <CheckboxGroup
                          value={watch('irradiation')?.split(',')}
                          multiselect
                          orientation="vertical"
                          options={irradiationLabel.options}
                          onChange={(values: string[]) => {
                            setValue('irradiation', values.join(','), {
                              shouldValidate: true
                            })
                          }}
                        />
                      </div>
                    ),
                    rightAddon: watch('irradiation') && (
                      <div className="w-[22px] h-[22px] rounded-full bg-primary-50 flex flex-col justify-center items-center">
                        <FaCheck size={12} className="text-white" />
                      </div>
                    ),
                    id: uuid()
                  },
                  {
                    title: t('Origin'),
                    content: (
                      <Dropdown
                        options={countryOptions}
                        placeholder={t('Choose Origin')}
                        size="sm"
                        value={watch('origin')?.split(',')}
                        isMulti
                        isSearchable
                        initialMenuOpen
                        ignoreClickOutside
                        onChange={(values: ValueProps[]) =>
                          setValue(
                            'origin',
                            values.map((value) => value.value).join(',')
                          )
                        }
                        menuPosition="relative"
                        buttonClassName="border-[0.5px] border-solid border-primary"
                      />
                    ),
                    rightAddon: watch('origin') && (
                      <div className="w-[22px] h-[22px] rounded-full bg-primary-50 flex flex-col justify-center items-center">
                        <FaCheck size={12} className="text-white" />
                      </div>
                    ),
                    id: uuid()
                  }
                ]}
                footer={
                  <div className="flex flex-col items-center justify-center gap-3 py-2">
                    <Button
                      variant="alpha-contained"
                      brand="primary"
                      size="sm"
                      text={t('Apply')}
                      className="!font-semibold"
                      onClick={handleFilter}
                    />
                  </div>
                }
              />
              <GeneralDropdown
                containerClassName="block md:w-auto w-full bg-tertiary-10"
                menuClassName="!w-full md:!w-auto h-[220px] md:h-auto relative md:absolute !border-primary-25 bg-tertiary-10"
                onMenuToggle={onDropdownActive}
                buttonClassName="py-2 border-none h-[40px] min-w-full md:min-w-[120px]"
                menuDirection="bottom-right"
                placeholder="Sort"
                placeholderIcon={
                  <Sort
                    title="Sort"
                    width="20px"
                    height="20px"
                    className="text-inherit"
                  />
                }
              >
                <div className="flex flex-col w-[250px]">
                  {Object.keys(sort).map((key) => {
                    const direction = sort[key as keyof typeof sort].direction
                    return (
                      <button
                        key={key}
                        onClick={(e) => {
                          e.preventDefault()
                          handleSort(key as keyof typeof sort)
                        }}
                        className={`flex items-center justify-between font-semibold text-xs px-4 py-3 border-b border-solid border-primary-25 ${
                          direction ? 'text-primary' : 'text-grey'
                        }`}
                      >
                        <span>{sort[key].label}</span>
                        {direction ? (
                          direction === 'asc' ? (
                            <SortAscending className="text-inherit " />
                          ) : (
                            <SortDescending className="text-inherit" />
                          )
                        ) : null}
                      </button>
                    )
                  })}
                </div>
              </GeneralDropdown>
            </div>
          </div>
          <div className="md:hidden w-full">
            <button
              onClick={() => reset()}
              style={{ zIndex: 99999999 }}
              className="bg-primary/75 active:bg-primary/90 text-primary-10 w-full h-[60px] flex justify-center items-center text-sm font-normal"
            >
              <span className="text-center">{t('Clear all')}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductSearchAndFilter
